import "./portfolio.css";

import React, { useState } from "react";
import { Divider } from "@mui/material";
import PortfolioProjects from "./PortfolioCards/PortfolioProjects";
import PortfolioDesign from "./PortfolioCards/PortfolioDesign";
import { isMobile } from "react-device-detect";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import PortfolioArt from "./PortfolioCards/PortfolioArt";

const Card1 = () => <PortfolioProjects />;
const Card2 = () => <PortfolioDesign />;
// const Card3 = () => <PortfolioArt  />;

const contentComponents = [Card1, Card2,];
const headings = ["Projects", "Designs", "Art"];

function Portfolio() {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [lastArrowDirection, setLastArrowDirection] = useState(null);

	const ARROW_DIRECTIONS = {
		LEFT: "left",
		RIGHT: "right",
	};

	const renderContent = () => {
		const CurrentContentComponent = contentComponents[currentIndex];
		return <CurrentContentComponent />;
	};

	const handleFadedHeadingClick = (newIndex) => {
		setLastArrowDirection(
			newIndex < currentIndex ? ARROW_DIRECTIONS.LEFT : ARROW_DIRECTIONS.RIGHT,
		);
		setCurrentIndex(newIndex);
	};

	const leftFadedIndex =
		currentIndex > 0 ? currentIndex - 1 : contentComponents.length - 1;
	const rightFadedIndex =
		currentIndex < contentComponents.length - 1 ? currentIndex + 1 : 0;

	const handleArrowClick = (direction) => {
		setLastArrowDirection(direction);
		setCurrentIndex((prevIndex) => {
			let newIndex;

			if (direction === ARROW_DIRECTIONS.LEFT) {
				newIndex =
					prevIndex === 0 ? contentComponents.length - 1 : prevIndex - 1;
			} else {
				newIndex =
					prevIndex === contentComponents.length - 1 ? 0 : prevIndex + 1;
			}

			return newIndex;
		});
	};

	return (
		<div className="portfolio-container">
			<div className="portfolio" id="portfolio-container">
				<div className="portfolio-headings">
					<div className={"faded-headings left-faded-headings "}>
						<div
							key={
								headings[
									currentIndex > 0
										? currentIndex - 1
										: contentComponents.length - 1
								]
							}
							onClick={() => handleFadedHeadingClick(leftFadedIndex)}
							className={`${
								currentIndex > 0 ? "active " : ""
							}left-faded-heading ${
								lastArrowDirection === ARROW_DIRECTIONS.LEFT
									? "fade-enter-left"
									: "fade-enter-right"
							}`}
						>
							{
								headings[
									currentIndex > 0
										? currentIndex - 1
										: contentComponents.length - 1
								]
							}
						</div>
						<Divider
							orientation="vertical"
							variant="middle"
							sx={{ height: "30%", borderWidth: "1px" }}
						/>
					</div>

					<div className="main-heading ">
						<h2>{headings[currentIndex]}</h2>
					</div>
					<div className={"faded-headings right-faded-headings"}>
						<Divider
							orientation="vertical"
							variant="middle"
							sx={{ height: "30%", borderWidth: "1px" }}
						/>
						<div
							key={
								headings[
									currentIndex < contentComponents.length - 1
										? currentIndex + 1
										: 0
								]
							}
							onClick={() => handleFadedHeadingClick(rightFadedIndex)}
							className={`${
								currentIndex < contentComponents.length - 1 ? "active " : ""
							}right-faded-heading ${
								lastArrowDirection === ARROW_DIRECTIONS.RIGHT
									? "fade-enter-right"
									: "fade-enter-left"
							}`}
						>
							{
								headings[
									currentIndex < contentComponents.length - 1
										? currentIndex + 1
										: 0
								]
							}
						</div>
					</div>
				</div>
				<div className="about-me-content">
					<div className="about-me-arrows-container">
						{!isMobile && (
							<ChevronLeftIcon
								className="about-me-arrows"
								style={{ transform: "scale(2)" }}
								onClick={() => handleArrowClick(ARROW_DIRECTIONS.LEFT)}
							/>
						)}
					</div>
					{renderContent()}
					<div className="about-me-arrows-container">
						{!isMobile && (
							<ChevronLeftIcon
								className="about-me-arrows"
								style={{ transform: "rotate(180deg) scale(2)" }}
								onClick={() => handleArrowClick(ARROW_DIRECTIONS.RIGHT)}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Portfolio;
