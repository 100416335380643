// PortfolioProjects component
import React, { useState } from "react";
import itemData from "../../../media/portfolio.json";
import ItemContainer from "../../common/ItemContainer";
import { useDispatch } from "react-redux";
import { openModal, setData } from "../../../store/reducers/animationSlice";

function PortfolioProjects() {
	const dispatch = useDispatch();

	const handleItemClick = (itemData) => {
		dispatch(openModal());
        dispatch(setData({ data: itemData, modalType: "other" }));
	};

	if (!itemData) return null;

	return (
		<div id="inner-portfolio" className="inner-portfolio">
			{itemData.items.map((item, index) => (
				<div key={index} onClick={() => handleItemClick(item)}>
					<ItemContainer item={item} index={index} />
					<div className="inner-portfolio-item-title">{item.title}</div>
				</div>
			))}
		</div>
	);
}

export default PortfolioProjects;
