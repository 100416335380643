import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header.js";
import AboutMe from "../components/layout/AboutMe.js";
import Portfolio from "../components/layout/Portfolio.js";
import Footer from "../components/layout/Footer.js";
import Svg from "../components/common/Svg.js";
import Contact from "../components/layout/Contact.js";
import PortfolioModal from "../components/layout/PortfolioCards/PortfolioModal.js";
import { selectModal } from "../store/reducers/animationSlice.js";
import { useSelector } from "react-redux";
import Loading from "../components/layout/LoadingModal/Loading.js";

function Home() {
	const [loadingOpen, setLoadingOpen] = useState(true);
	const modalOpen = useSelector(selectModal); 
	useEffect(() => {
        if (modalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [modalOpen]);

	return (
		<>
		<div className="content-container">
			<div className="overlay">
				<div className="floor"></div>
				<Header />
				<div className="scene"><Svg /></div>
			</div>
			<div className="overlay-content">
				<AboutMe />
				<Portfolio /> 
				<Contact/>
			</div>
			<Footer />
		</div>
		{modalOpen &&<PortfolioModal/>}
		{loadingOpen && <Loading />}
		</>
	);
}

export default Home;
