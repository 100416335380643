import templateData from "../../../media/templates.json";
import { useDispatch } from "react-redux";
import { openModal, setData } from "../../../store/reducers/animationSlice";
import ItemContainer from "../../common/ItemContainer";

function PortfolioDesign() {
	const dispatch = useDispatch();

	const handleItemClick = (templateData) => {
		dispatch(openModal());
        dispatch(setData({ data: templateData, modalType: "other" }));
	};

	if (!templateData || !templateData.items) return null;
	return (
		<div id="inner-portfolio" className="inner-portfolio" style={{gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))"}}>
			{templateData.items.map((template, index) => (
				<div key={index} onClick={() => handleItemClick(template)} className="design-mobile">
					<ItemContainer template={template} index={index} />
					<div className="inner-portfolio-item-title">{template.title}</div>
				</div>
			))}
		</div>
	);
}

export default PortfolioDesign;
