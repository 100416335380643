import React, { useEffect, useState } from "react";
import "./loading.css";
import { ReactComponent as SuitcaseSvg1 } from "../../../media/svgs/suitcase1.svg";

function Loading() {
    const [showSuitcase, setShowSuitcase] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            const modal = document.getElementById("animation-loading");
            modal.classList.add("animate");
            setTimeout(() => {
                setShowSuitcase(false);
            }, 500); // Delay to allow the animation to play before hiding the suitcase
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="loading-container" id="animation-loading">
            {showSuitcase && (
                <div className="glowing-square">
                    <SuitcaseSvg1
                        className="loading-icon-container"
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            )}
        </div>
    );
}

export default Loading;
