import React from "react";
import "./cards.css";
import skills from "../../../media/skills.json";
import { Divider } from "@mui/material";

import { ReactComponent as HtmlIcon } from "../../../media/svgs/skills/html.svg";
import { ReactComponent as CssIcon } from "../../../media/svgs/skills/css.svg";
import { ReactComponent as BlenderIcon } from "../../../media/svgs/skills/blender.svg";
import { ReactComponent as JsIcon } from "../../../media/svgs/skills/js.svg";
import { ReactComponent as ReactIcon } from "../../../media/svgs/skills/react.svg";
import { ReactComponent as PythonIcon } from "../../../media/svgs/skills/python.svg";
import { ReactComponent as TreeIcon } from "../../../media/svgs/skills/tree.svg";
import { ReactComponent as GraphicIcon } from "../../../media/svgs/skills/graphic.svg";
import { ReactComponent as UxIcon } from "../../../media/svgs/skills/ux.svg";
import { ReactComponent as MongodbIcon } from "../../../media/svgs/skills/mongodb.svg";
import { ReactComponent as MysqlIcon } from "../../../media/svgs/skills/mysql.svg";
import { ReactComponent as CdIcon } from "../../../media/svgs/skills/cd.svg";
import { ReactComponent as GitIcon } from "../../../media/svgs/skills/git.svg";
import { ReactComponent as PhotoshopIcon } from "../../../media/svgs/skills/photoshop.svg";


function AboutMeSkills() {
	const tehnical = skills.tehnical;
	const soft = skills.soft;

	const iconComponents = {
		html: <HtmlIcon className="icon-class" />,
		css: <CssIcon className="icon-class" />,
		blender: <BlenderIcon className="icon-class" />,
		js: <JsIcon className="icon-class" />,
		react: <ReactIcon className="icon-class" />,
		python: <PythonIcon className="icon-class" />,
		tree: <TreeIcon className="icon-class" />,
		graphic: <GraphicIcon className="icon-class" />,
		ux: <UxIcon className="icon-class" />,
		mongodb: <MongodbIcon className="icon-class" />,
		mysql: <MysqlIcon className="icon-class" />,
		cd: <CdIcon className="icon-class" />,
		git: <GitIcon className="icon-class" />,
		photoshop: <PhotoshopIcon className="icon-class" />
	};

	return (
		<div className="about-me-cards">
			<div className="about-me-skills">
				<div className="about-me-skills-tehnical">
					<h4 className="about-me-skills-heading">Tehnical:</h4>
					<Divider  orientation="horizontal" variant="middle" flexItem />
					<div className="about-me-skills-center">
						<div className="about-me-skills-wrap">
							{tehnical.map((item, index) => (
								<div key={index} className="about-me-skills-item">
									<div>{iconComponents[item.icon]}</div>
									{item.name}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="about-me-skills-soft">
					<h4 className="about-me-skills-heading" style={{marginTop:"-5px"}}>Soft Skills:</h4>
					<Divider  orientation="horizontal" variant="middle" flexItem />
					<div className="about-me-skills-center">
						<div className="about-me-skills-wrap">
							{soft.map((item, index) => (
								<div key={index} className="about-me-skills-item">
									- {item}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutMeSkills;
