import { Divider } from "@mui/material";
import React, { useState } from "react";

import { ReactComponent as DiscordIcon } from "../../media/svgs/contact/discord.svg";
import { ReactComponent as EmailIcon } from "../../media/svgs/contact/email.svg";
import { ReactComponent as GitIcon } from "../../media/svgs/contact/git.svg";
import { ReactComponent as LeetcodeIcon } from "../../media/svgs/contact/leetcode.svg";
import { ReactComponent as PhoneIcon } from "../../media/svgs/contact/phone.svg";

function Footer() {
	const [isLinkCopied, setIsLinkCopied] = useState({
		1: false,
		2: false,
		3: false,
		4: false,
		null: false,
	});

	const copyToClipboard = (text, key) => {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("copy");
		document.body.removeChild(textArea);
		setIsLinkCopied({ ...isLinkCopied, [key]: true });

		setTimeout(() => {
			setIsLinkCopied({ ...isLinkCopied, [key]: false });
		}, 2000);
	};

	const handleCopy = (text, key) => {
		copyToClipboard(text, key);
	};

	const handleOpenLink = (url) => {
		window.location.href = url;
	};

	return (
		<div className="footer">
			<div className="inner-footer">
				{[
					{
						text: "Miicy",
						key: null,
						url: "https://github.com/Miicy",
						icon: <GitIcon className="footer-svg"/>,
					},
					{
						text: "miicy",
						key: 4,
						url: "https://leetcode.com/miicy/",
						icon: <LeetcodeIcon className="footer-svg"/>,
					},
					{ text: "Miicy", key: 3, icon: <DiscordIcon className="footer-svg"/> },
					{ text: "+381 691408997", key: 2, icon: <PhoneIcon className="footer-svg"/> },
					{
						text: "milicapantelic.info@gmail.com",
						key: 1,
						icon: <EmailIcon className="footer-svg"/>,
					},
				].map((item, index) => (
					<React.Fragment key={index}>
						{index > 0 && (
							<Divider
							    className="footer-divider"
								orientation="vertical"
								flexItem
								sx={{ border: "0.5px solid rgba(0, 0, 0,0.4)" }}
							/>
						)}
						<div
							className="footer-links"
							onClick={() => {
								if (item.url) {
									handleOpenLink(item.url);
								} else {
									handleCopy(item.text, item.key);
								}
							}}
						>
							{item.icon && <div className="footer-icon">{item.icon}</div>}
							<div className="links footer1">{item.text}</div>
							{isLinkCopied[item.key] && <div className="copied"> Copied!</div>}
						</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
}

export default Footer;
