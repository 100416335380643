import React, { useState } from "react";
import { useGetImages } from "../../helpers/useGetImages";
import { useGetImagesCards } from "../../helpers/useGetImagesCards";

function ItemContainer({ item, template, art, index }) {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	const backgroundImage = useGetImagesCards(item, template, art, index);

	return (
		<>
			<div
				className="item-container"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onTouchStart={handleMouseEnter}
				onTouchEnd={handleMouseLeave}
			>
				<div
					className="item-inner-container"
					style={{
						backgroundImage: `url(${backgroundImage})`,
						backgroundSize: "cover",
						backgroundPosition: " top ",
						backgroundRepeat: "no-repeat",
						filter: isHovered ? "blur(0px)" : "blur(0.6px)",
					}}
				></div>
			</div>
		</>
	);
}

export default ItemContainer;
