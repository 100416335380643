import React from "react";
import "./cards.css";
import info from "../../../media/cetificates.json";
import { Divider } from "@mui/material";

function AboutMeCertificates() {
	const certificates = info.certificates;
	const education = info.education;

	console.log(certificates);
	return (
		<div className="about-me-cards">
			<div className="about-me-certiticates-content">
				<h4 className="about-me-certiticates-heading">Education:</h4>
				<Divider  orientation="horizontal" flexItem />
				<div className="about-me-certiticates-display">
					<div className="about-me-certiticates-display-column">
						<div className="about-me-certiticates-name">{education.name}</div>
						<div className="about-me-certiticates-institution">
							{education.institution}
						</div>
					</div>
					{education.completionDate}
				</div>
				<h4 className="about-me-certiticates-heading">Other Certificates:</h4>
				<Divider  orientation="horizontal" flexItem />
				{certificates.slice().reverse().map((certificate, index) => (
					<div key={index} className="about-me-certiticates-display">
						<div className="about-me-certiticates-display-column">
							<div className="about-me-certiticates-name">
								{certificate.name}
							</div>
							<div className="about-me-certiticates-institution">
								{certificate.institution}
							</div>
						</div>
						{certificate.completionDate}
					</div>
				))}
			</div>
		</div>
	);
}

export default AboutMeCertificates;
