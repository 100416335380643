import { useEffect, useMemo, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"; // Import OrbitControls from the correct path
import { isMobile } from "react-device-detect";

function CanvasComponent({ path }) {
	const containerRef = useRef();
	const modelPath = path;
	const scene = useMemo(() => new THREE.Scene(), []);
	const camera = useMemo(
		() =>
			new THREE.PerspectiveCamera(
				30,
				window.innerWidth / window.innerHeight,
				0.1,
				1000,
			),
		[],
	);

	const renderer = useMemo(
		() => new THREE.WebGLRenderer({ alpha: true, antialias: false }),
		[],
	);

	const controls = useRef(null);

	useEffect(() => {
		const loader = new GLTFLoader();
		loader.load(
			modelPath,
			(gltf) => {
				const model = gltf.scene;
				scene.add(model);

				const box = new THREE.Box3().setFromObject(model);
				const sphere = new THREE.Sphere();
				box.getBoundingSphere(sphere);
				const center = sphere.center;
				const radius = sphere.radius;

				model.position.set(-center.x, -center.y, -center.z);

                const cameraZ = isMobile ? center.z + radius * 4 : center.z + radius * 3;
                camera.position.set(center.x, center.y, cameraZ);
                camera.lookAt(center);

				// Initialize controls
				controls.current = new OrbitControls(camera, renderer.domElement);
				controls.current.enableZoom = false;
				controls.current.enablePan = false;
				controls.current.enableRotate = true;

				const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
				directionalLight1.position.set(
					center.x,
					center.y + radius * 2,
					center.z + radius,
				);
				scene.add(directionalLight1);

				const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
				directionalLight2.position.set(
					center.x - radius,
					center.y,
					center.z + radius,
				);
				scene.add(directionalLight2);

				const directionalLightBack1 = new THREE.DirectionalLight(0xffffff, 1);
				directionalLightBack1.position.set(
					center.x,
					center.y - radius * 2,
					center.z - radius,
				);
				scene.add(directionalLightBack1);

				const directionalLightBack2 = new THREE.DirectionalLight(0xffffff, 0.5);
				directionalLightBack2.position.set(
					center.x + radius,
					center.y,
					center.z - radius,
				);
				scene.add(directionalLightBack2);

				const sunlight = new THREE.DirectionalLight(0xffffff, 1);
				sunlight.position.set(0, 100, 0);
				scene.add(sunlight);

				const frontLight = new THREE.DirectionalLight(0xffffff, 1);
				frontLight.position.set(center.x, center.y, center.z + radius * 2);
				scene.add(frontLight);

				// Render loop
				const animate = () => {
					requestAnimationFrame(animate);

					// Update controls
					controls.current.update();

					// Render scene
					renderer.render(scene, camera);
				};

				animate();
			},
			undefined,
			(error) => {
				console.error("Error loading model:", error);
			},
		);
	}, [scene, camera, controls, renderer, modelPath]);

	useEffect(() => {
		const container = containerRef.current;

		if (container && renderer.domElement) {
			container.appendChild(renderer.domElement);
		}

		return () => {
			if (container && renderer.domElement) {
				container.removeChild(renderer.domElement);
			}
		};
	}, [renderer, containerRef]);

	useEffect(() => {
		const handleResize = () => {
			if (containerRef.current) {
				const width = containerRef.current.clientWidth;
				const height = containerRef.current.clientHeight;
				renderer.setSize(width, height);
				camera.aspect = width / height;
				camera.updateProjectionMatrix();
			}
		};

		handleResize(); // Set initial size

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [containerRef, renderer, camera]);

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<div
				ref={containerRef}
				style={{
					height: "500px",
					maxHeight: "500px",
					width: "90%",
					background: "linear-gradient(to bottom, #DFD8D8,#AB99A7, #775975)",
					boxShadow: "inset 0px 0px 22px 4px rgba(151,118,149,1)",
				}}
			></div>
		</div>
	);
}

export default CanvasComponent;
