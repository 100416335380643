import CloseIcon from "@mui/icons-material/Close";
import { useGetImages } from "../../../helpers/useGetImages";
import {
	closeModal,
	selectData,
	selectModalType,
} from "../../../store/reducers/animationSlice";
import { useDispatch, useSelector } from "react-redux";
import CanvasComponent from "./CanvasComponent";

import { ReactComponent as HtmlIcon } from "../../../media/svgs/skills/html.svg";
import { ReactComponent as CssIcon } from "../../../media/svgs/skills/css.svg";
import { ReactComponent as BlenderIcon } from "../../../media/svgs/skills/blender.svg";
import { ReactComponent as JsIcon } from "../../../media/svgs/skills/js.svg";
import { ReactComponent as ReactIcon } from "../../../media/svgs/skills/react.svg";
import { ReactComponent as PythonIcon } from "../../../media/svgs/skills/python.svg";
import { ReactComponent as TreeIcon } from "../../../media/svgs/skills/tree.svg";
import { ReactComponent as GraphicIcon } from "../../../media/svgs/skills/graphic.svg";
import { ReactComponent as UxIcon } from "../../../media/svgs/skills/ux.svg";
import { ReactComponent as MongodbIcon } from "../../../media/svgs/skills/mongodb.svg";
import { ReactComponent as MysqlIcon } from "../../../media/svgs/skills/mysql.svg";
import { ReactComponent as CdIcon } from "../../../media/svgs/skills/cd.svg";
import { ReactComponent as GitIcon } from "../../../media/svgs/skills/git.svg";
import { ReactComponent as PhotoshopIcon } from "../../../media/svgs/skills/photoshop.svg";
import { ReactComponent as ReduxIcon } from "../../../media/svgs/skills/redux.svg";
import { ReactComponent as SketchbookIcon } from "../../../media/svgs/skills/sketchbook.svg";
import { ReactComponent as MaterialUIIcon } from "../../../media/svgs/skills/materialui.svg";
import { ReactComponent as DjangoIcon } from "../../../media/svgs/skills/django.svg";
import { useEffect, useState } from "react";
import LoadingCircle from "./LoadingCircle";

function PortfolioModal() {
	const dispatch = useDispatch();
	const data = useSelector(selectData);
	const modalType = useSelector(selectModalType);
	const backgroundImage = useGetImages(data);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	const isItemValid = data && data.link;

	const handleItemClick = () => {
		if (isItemValid) {
			window.open(data.link, "_blank");
		}
	};

	const handleItemClick2 = () => {
		if (isItemValid) {
			window.open(data.link2, "_blank");
		}
	};

	if (!data) return null;

	const iconComponents = {
		html: { icon: <HtmlIcon className="icon-modal" />, name: "HTML" },
		css: { icon: <CssIcon className="icon-modal" />, name: "CSS" },
		blender: { icon: <BlenderIcon className="icon-modal" />, name: "Blender" },
		javascript: { icon: <JsIcon className="icon-modal" />, name: "JavaScript" },
		react: { icon: <ReactIcon className="icon-modal" />, name: "React" },
		python: { icon: <PythonIcon className="icon-modal" />, name: "Python" },
		tree: { icon: <TreeIcon className="icon-modal" />, name: "Tree" },
		graphic: {
			icon: <GraphicIcon className="icon-modal" />,
			name: "Graphic Design",
		},
		ux: { icon: <UxIcon className="icon-modal" />, name: "Figma" },
		mongodb: { icon: <MongodbIcon className="icon-modal" />, name: "MongoDB" },
		mysql: { icon: <MysqlIcon className="icon-modals" />, name: "MySQL" },
		cd: {
			icon: <CdIcon className="icon-modal" />,
			name: "Continuous Delivery",
		},
		git: { icon: <GitIcon className="icon-modal" />, name: "Git" },
		photoshop: {
			icon: <PhotoshopIcon className="icon-modal" />,
			name: "Photoshop",
		},
		redux: { icon: <ReduxIcon className="icon-modal" />, name: "Redux" },
		sketchbook: {
			icon: <SketchbookIcon className="icon-modal" />,
			name: "Sketchbook",
		},
		materialui: {
			icon: <MaterialUIIcon className="icon-modal" />,
			name: "MaterialUI",
		},
		django: { icon: <DjangoIcon className="icon-modal" />, name: "Django" },
	};

	const renderIcon = (stackItem) => {
		const iconEntry = iconComponents[stackItem.toLowerCase()];

		if (iconEntry && iconEntry.icon && iconEntry.name) {
			const { icon, name } = iconEntry;
			return (
				<div key={stackItem} className="icon-item">
					{icon}
					<span className="icon-name">{name}</span>
				</div>
			);
		} else {
			return null;
		}
	};

	return modalType === "art" ? (
		<div className="portfolio-modal">
			<div className="portfolio-modal-container">
				<div className="portfolio-modal-close-container">
					<CloseIcon
						onClick={() => dispatch(closeModal())}
						className="portfolio-modal-close-icon"
						sx={{ color: "rgb(119, 89, 117) " }}
					/>
				</div>
				<div className="portfolio-modal-heading">
					<h1>{data.title}</h1>
				</div>
				<div className="portfolio-modal-inner-container">
					<div
						style={{
							height: "100%",
							width: "100%",
							display: "flex",
							justifyContent: "center",
						}}
					>
						{data.fileType === "img" ? (
							<img
								src={backgroundImage}
								alt={data.fileName}
								className="portfolio-modal-image-art"
								onContextMenu={(e) => e.preventDefault()}
							/>
						) : (
							data.fileType === "3d" && (
								<>
									{isLoading && <LoadingCircle />}
									<CanvasComponent data={data} path={backgroundImage} />
								</>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="portfolio-modal">
			<div className="portfolio-modal-container">
				<div className="portfolio-modal-close-container">
					<CloseIcon
						onClick={() => dispatch(closeModal())}
						className="portfolio-modal-close-icon"
						sx={{ color: "rgb(119, 89, 117) " }}
					/>
				</div>
				<div className="portfolio-modal-inner-container">
					<div className="portfolio-modal-left">
						<div className="portfolio-modal-image-container">
							<div
								className="portfolio-modal-image"
								style={{
									backgroundImage: `url(${backgroundImage})`,
									backgroundSize: "cover",
									backgroundPosition: " top ",
									backgroundRepeat: "no-repeat",
								}}
							></div>
						</div>
						<div
							className="portfolio-modal-link-button"
							onClick={handleItemClick}
						>
							Open Project
						</div>
						{/* {data.link2 && <div
							className="portfolio-modal-link-button link2"
							onClick={handleItemClick2}
						>
							Open API
						</div>} */}
					</div>
					<div className="portfolio-modal-right">
						<div className="portfolio-modal-heading">
							<h1>{data.title}</h1>
						</div>
						<div className="portfolio-modal-tech">
							{data.stack.split(", ").map((stackItem) => renderIcon(stackItem))}
						</div>
						<div className="portfolio-modal-description">
							<p
								dangerouslySetInnerHTML={{
									__html: `${data.description.replace(/\n/g, "<br><br>")}`,
								}}
							></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PortfolioModal;
