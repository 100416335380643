import image1 from "../media/images/webshop.png";
import image2 from "../media/images/news.png";
import image3 from "../media/images/interactive.png";
import template1 from "../media/images/rpg.png";
import template2 from "../media/images/techwear.png";
import template3 from "../media/images/coven.png";
import template4 from "../media/images/seaside.png";
import art1 from "../media/art/bookcover.png";
import art2 from "../media/art/logo.svg";
import art3 from "../media/art/armor.gltf";

export function useGetImages(data) {
  const imagesMap = {
    "Web Shop": image1,
    "News Feature": image2,
    "Interactive Map": image3,
    "RPG Login": template1,
    "Techwear Homepage": template2,
    "Coven Login": template3,
    "Seaside Animations": template4,
    "Book Cover": art1,
    "Logo Brutal" : art2,
    "3D - Armor" : art3,
  };

  if (data && data.title) {
    const matchingImage = imagesMap[data.title];
	// console.log(matchingImage)
    return matchingImage || "none";

  }


  return "none";
}