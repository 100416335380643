import image1 from "../media/images/webshop.png";
import image2 from "../media/images/news.png";
import image3 from "../media/images/interactive.png";
import template1 from "../media/images/rpg.png";
import template2 from "../media/images/techwear.png";
import template3 from "../media/images/coven.png";
import template4 from "../media/images/seaside.png";
import art1 from "../media/art/bookcover.png";
import art2 from "../media/art/logo.png";
import art3 from "../media/art/armor.png";

export function useGetImagesCards(item, template, art, index) {
	const portfolioImages = [image2, image1, image3];
	const templateImages = [template1, template2, template3, template4];
	const artImages = [art1, art2, art3];
	if (item) {
		return portfolioImages[index] || "none";
	} else if (template) {
		return templateImages[index] || "none";
	} else if (art) {
		return artImages[index] || "none";
	}
	return "none";
}
