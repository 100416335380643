import "./about-me.css";
import { Divider,} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AboutMeInfo from "./AboutMeCards/AboutMeInfo";
import { useState } from "react";
import AboutMeCertificates from "./AboutMeCards/AboutMeCertificates";
import AboutMeSkills from "./AboutMeCards/AboutMeSkills";
import { isMobile } from "react-device-detect";

const Card1 = () => <AboutMeInfo />;
const Card2 = () => <AboutMeCertificates />;
const Card3 = () => <AboutMeSkills />;

const contentComponents = [Card1, Card2, Card3];
const headings = ["About me", "Certificates", "Skills"];

function AboutMe() {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [lastArrowDirection, setLastArrowDirection] = useState(null);

	const ARROW_DIRECTIONS = {
		LEFT: "left",
		RIGHT: "right",
	};

	const handleArrowClick = (direction) => {
		setLastArrowDirection(direction);
		setCurrentIndex((prevIndex) => {
			let newIndex;

			if (direction === ARROW_DIRECTIONS.LEFT) {
				newIndex =
					prevIndex === 0 ? contentComponents.length - 1 : prevIndex - 1;
			} else {
				newIndex =
					prevIndex === contentComponents.length - 1 ? 0 : prevIndex + 1;
			}

			return newIndex;
		});
	};

	const renderContent = () => {
		const CurrentContentComponent = contentComponents[currentIndex];
		return <CurrentContentComponent />;
	};

	const handleFadedHeadingClick = (newIndex) => {
		setLastArrowDirection(
			newIndex < currentIndex ? ARROW_DIRECTIONS.LEFT : ARROW_DIRECTIONS.RIGHT,
		);
		setCurrentIndex(newIndex);
	};

	const leftFadedIndex =
		currentIndex > 0 ? currentIndex - 1 : contentComponents.length - 1;
	const rightFadedIndex =
		currentIndex < contentComponents.length - 1 ? currentIndex + 1 : 0;

	return (
		<div className="about-me-container">
			<div className="about-me-headings">
				<div className={"faded-headings left-faded-headings "}>
					<div
						key={
							headings[
								currentIndex > 0
									? currentIndex - 1
									: contentComponents.length - 1
							]
						}
						onClick={() => handleFadedHeadingClick(leftFadedIndex)}
						className={`${
							currentIndex > 0 ? "active " : ""
						}left-faded-heading ${
							lastArrowDirection === ARROW_DIRECTIONS.LEFT
								? "fade-enter-left"
								: "fade-enter-right"
						}`}
					>
						{
							headings[
								currentIndex > 0
									? currentIndex - 1
									: contentComponents.length - 1
							]
						}
					</div>
					<Divider  orientation="vertical" variant="middle" sx={{height:"30%", borderWidth:"1px"}}/>
				</div>
				
				<div className="main-heading ">
					<h2>{headings[currentIndex]}</h2>
				</div>
				<div className={"faded-headings right-faded-headings"}>
				<Divider  orientation="vertical" variant="middle" sx={{height:"30%", borderWidth:"1px"}}/>
					<div
						key={
							headings[
								currentIndex < contentComponents.length - 1
									? currentIndex + 1
									: 0
							]
						}
						onClick={() => handleFadedHeadingClick(rightFadedIndex)}
						className={`${
							currentIndex < contentComponents.length - 1 ? "active " : ""
						}right-faded-heading ${
							lastArrowDirection === ARROW_DIRECTIONS.RIGHT
								? "fade-enter-right"
								: "fade-enter-left"
						}`}
					>
						{
							headings[
								currentIndex < contentComponents.length - 1
									? currentIndex + 1
									: 0
							]
						}
					</div>
				</div>
			</div>

			<div className="about-me-content">
				<div className="about-me-arrows-container">
					{!isMobile && (
						<ChevronLeftIcon
							className="about-me-arrows"
							style={{ transform: "scale(2)" }}
							onClick={() => handleArrowClick(ARROW_DIRECTIONS.LEFT)}
						/>
					)}
				</div>
				{renderContent()}
				<div className="about-me-arrows-container">
					{!isMobile && (
						<ChevronLeftIcon
							className="about-me-arrows"
							style={{ transform: "rotate(180deg) scale(2)" }}
							onClick={() => handleArrowClick(ARROW_DIRECTIONS.RIGHT)}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default AboutMe;
