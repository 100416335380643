import React from "react";
import "./cards.css";
import wordFile from "../../../media/CV.pdf";
import DownloadIcon from "@mui/icons-material/Download";
import text from "../../../media/about-me.json";
import img from "../../../media/me-image.png";
function AboutMeInfo() {
	const textArray = text.text.split("\n");
	return (
		<div className="about-me-cards">
			<div className="about-me-info-content">
				<div className="about-me-image-container">
					<img src={img} alt="My Image" className="about-me-image" />
				</div>
				{textArray.map((line, index) => (
					<p style={{ textAlign: "justify" }} key={index}>
						{line}
					</p>
				))}
			</div>
			<a href={wordFile} download="Resume.pdf" className="resume-link">
				<DownloadIcon sx={{color: "black"}}/>
				<div className="links resume">Download Resume</div>
			</a>
		</div>
	);
}

export default AboutMeInfo;
