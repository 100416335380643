import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: {}, 
	isModalOpen: false,
  };

const animationSlice = createSlice({
	name: "animation",
	initialState,
	reducers: {
		setData: (state, action) => {
			const { data, modalType } = action.payload;
			state.data = data;
			state.modalType = modalType;
		},
		  openModal: (state) => {
			state.isModalOpen = true;
		  },
		  closeModal: (state) => {
			state.isModalOpen = false;
		  },
	},
});

export const { setData, openModal, closeModal } = animationSlice.actions;
export const selectData = (state) => state.animation.data;
export const selectModal = (state) => state.animation.isModalOpen;
export const selectModalType = (state) => state.animation.modalType;

export default animationSlice.reducer;