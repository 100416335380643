import "../../pages/pages.css";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Divider, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function Header() {
	const isScreenSmall = useMediaQuery("(max-width: 480px)");

	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			setIsSticky(scrollPosition > 100);
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	function scrollToPosition(scrollPosition) {
		window.scrollTo({
			top: scrollPosition,
			behavior: "smooth",
		});
	}
	const [isMenuOpen, setMenuOpen] = useState(false);

	function toggleMenu() {
		setMenuOpen(!isMenuOpen);
	}

	const handleScroll = (scrollPosition) => {
		scrollToPosition(scrollPosition);
	};

	const headerLinks = [
		{
			label: "About me",
			scrollPosition: isScreenSmall || isMobile ? 700 : 1000,
		},
		{
			label: "Portfolio",
			scrollPosition: isScreenSmall || isMobile ? 1550 : 1850,
		},
		{
			label: "Contact",
			scrollPosition: isScreenSmall || isMobile ? 3000 : 3000,
		},
	];

	const dividerStyles = { border: "0.5px solid rgba(0, 0, 0,0.4)" };

	return (
		<>
			{isSticky && <div className="empty-header" />}

			<div
				className={`header ${isSticky ? "sticky" : ""}`}
				style={
					isMobile || (isSticky && isScreenSmall)
						? { justifyContent: "space-between" }
						: {}
				}
			>
				<div className="heading-left-container">
					<p className="heading">Milica Pantelic</p>
					<p className="heading-middle">Developer</p>
				</div>
				<div className="heading-right-container">
					{isScreenSmall || isMobile ? (
						<>
							<MenuIcon
								sx={{ cursor: "pointer", fontSize: "2em" }}
								onClick={toggleMenu}
							/>
							{isMenuOpen && (
								<div
									className={`heading-right-menu${
										isMobile && isSticky
											? " heading-right-menu-sticky-mobile"
											: ""
									}`}
								>
									{headerLinks.map((link, index) => (
										<React.Fragment key={index}>
											<p
												className="links-menu"
												onClick={() => handleScroll(link.scrollPosition)}
											>
												{link.label}
											</p>
											{index < headerLinks.length - 1 && (
												<Divider
													orientation="vertical"
													flexItem
													sx={dividerStyles}
												/>
											)}
										</React.Fragment>
									))}
								</div>
							)}
						</>
					) : (
						headerLinks.map((link, index) => (
							<React.Fragment key={index}>
								<p
									className="links"
									onClick={() => handleScroll(link.scrollPosition)}
								>
									{link.label}
								</p>
								{index < headerLinks.length - 1 && (
									<Divider orientation="vertical" flexItem sx={dividerStyles} />
								)}
							</React.Fragment>
						))
					)}
				</div>
			</div>
		</>
	);
}

export default Header;
