import React from "react";
import "./loadingCircle.css";
import { isMobile } from "react-device-detect";

function LoadingCircle() {
	return (
		<div
			style={{
				backgroundColor: "white",
				height: isMobile ? "75%" : "70%",
				width: "100%",
				zIndex: 500,
				position: "absolute",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div class="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export default LoadingCircle;
